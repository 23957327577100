import * as React from 'react';
import NavPlats from './navPlats/navPlats';

import NavDatePicker from './navDatePicker/navDatePicker';
import './nav.css';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import NavDateToggle from './navDateToggle/navDateToggle';
import NavFormList from './navFormList/navFormList';



export default function Nav(props) {
    
  return (
    <Container id='filter' maxWidth='xl' sx={{ display: {lg:'flex'},justifyContent: {lg:'center'}, backgroundColor:'#d3d3d340', borderTop: '1px solid #dee2e6', borderBottom: '1px solid #dee2e6'}}>
      
      <Box  sx={{width:'100%', paddingLeft:{lg:'24px'}, paddingRight:'24px', maxWidth:'1200px', pb:3, display: 'flex', flexDirection:{xs: 'column', sm:'row'}}}>
          <Box sx={{display: 'flex', flexDirection:'row'}}>
            <Box sx={{mr:3, mt: 3}} >
              <Typography variant="subtitle1" component="div" sx={{mb:2}}>Genre</Typography>  
              <NavFormList allSelected={props.allSelected} formsInData={props.formsInData} formats={props.formats} handleFormat={props.handleFormat} transformShows={props.transformShows}/>
            </Box>
            <Box sx={{mr:3, mt:3}}> 
              <Typography variant="subtitle1" component="div" sx={{mb:2}}>Var</Typography>  
              <NavPlats isAllSelected ={props.isAllSelected} platsInData ={props.platsInData} scen={props.scen} handleChange={props.handleChange}/>
            </Box>
          </Box>
         
          <Box sx={{mr:3, mt:3}}>
            <Typography variant="subtitle1" component="div" sx={{mb:2}}>När</Typography>  
            <NavDateToggle dateToggle = {props.dateToggle} setDateToggle = {props.setDateToggle} handleDateToggle={props.handleDateToggle}/>
          </Box>
          <Box sx={{mr:3, mt:3}}>
            <Typography variant="subtitle1" component="div" sx={{mb:2}}>Specifika datum</Typography>  
            <NavDatePicker handleDateToggle={props.handleDateToggle} setDateToggle={props.setDateToggle} timeValue={props.timeValue} setValue={props.setValue} open={props.open} setOpen={props.setOpen} isAccept={props.isAccept} setIsAccept ={props.setIsAccept} isTextField={props.isTextField} setIsTextField ={props.setIsTextField} />
          </Box>
      </Box>     
    </Container>
    

    
        
                 
               
                            
        
        
      
   
  );
}