import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './Modal2.css';
import CloseIcon from '@mui/icons-material/Close';
import Helmet from 'react-helmet';
import Container from '@mui/material/Container';
import { Box } from '@mui/system';
import { analytics } from '../../firebase-config';
import { logEvent} from "firebase/analytics";
         

export default function CardsModal(props) {
  

  // Analytics config
  useEffect(() => {
    logEvent(analytics,`${props.namn}-modal-visited`);
  });


  var date = new Date(props.tid);
  var dateDay = date.toLocaleDateString("sv-SE", {day: "numeric"});
  var dateWeekday = date.toLocaleDateString("sv-SE", {weekday: "short"});
  var dateMonth = date.toLocaleDateString("sv-SE", {month: "short"});
  const addZero = (i) => {
      if(i<10) {i = '0'+ i}
      return i;
  }

  return (
    
      
      <Modal
        
        open={props.open}
        onClose={props.close}
        aria-labelledby={props.namn}
        aria-describedby={props.beskrivningKort}
        style = {{'overflow' : 'scroll', overflowX:'hidden'}}
       

      >
        

        
         
          
          
          <Container sx={{ maxWidth:{sm:'85%',md:'80%',lg:'60%'}}}>
            <Helmet>
              <title>SHOWY | {props.namn}</title>
              <meta 
                name='description'
                content={props.beskrivningKort}
              />
            </Helmet>
            
            <Box sx={{height:{xs:350, sm:'auto'}, backgroundColor:'black', color: 'white', border:0, position:'relative'}}>
                
                  <img id="card-img" src={props.bild1} alt="teater-bild" style={{objectFit: 'cover'}}/>
                  <div id="card-img-overlay">
                    
                    <button type="button" onClick={props.close} className="close" aria-label="Close">
                      <CloseIcon fontSize='small'/>
                    </button>
                    <Box sx={{position:'absolute', bottom: 30, width:{xs:'100%', md: '70%'}, pr:5}}>
                      <Typography variant="h2" component="div" sx={{mb:1}}>{props.namn}</Typography>
                      <Typography variant="subtitle1" component="div" sx={{mb:2}}>{props.plats} | {dateWeekday} {dateDay} {dateMonth} {date.getHours()}:{addZero(date.getMinutes())}</Typography>
                      <Typography variant="subtitle2" component="div" sx={{pr:'20px',mb: 2, display:{xs:'none', md:'block'} }}>{props.beskrivningKort}</Typography>
                      
                    </Box>
                    
                  
                  </div>
                  
            </Box>

            <Box sx={{minHeight:{xs:'500px',sm:'300px'}, display:'flex', flexDirection:'column', padding:'0 20px 0 20px', backgroundColor:'black', color:'white'}}>
                   
                  <Button href={props.biljett} target="_blank" size="large" variant="contained" sx={{mb:3, backgroundColor:'white !important', color:'black', width:'100%', borderRadius:100}}>Biljett</Button>
                  <Typography variant="p" component="div" sx={{mb:3}} dangerouslySetInnerHTML={{ __html: props.beskrivningLång.replace('\n','').replaceAll('\n\n','\n').replaceAll('\n','<br><br>') }}></Typography>
                  <Typography variant="p" component="div" sx={{mt:1}}><span className="text-muted">Av:</span> {props.författare}</Typography>
                  <Typography variant="p" component="div" sx={{mt:1}}><span className="text-muted">Info:</span> {props.speltid}</Typography>
                  <Typography variant="p" component="div" sx={{mt:1, mb:5, textTransform:'capitalize'}}><span className="text-muted">Genre: </span>{props.form}</Typography>
                                                        
            </Box>
           </Container>
  

        
      </Modal>
    
  );
}
