import * as React from 'react';
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';


export default function NavDatePicker(props) {
  
  return (
    
    <LocalizationProvider  dateAdapter={AdapterDateFns}>
      <DateRangePicker
        inputFormat="yyyy-MM-dd"
        mask="____-__-__"
        toolbarTitle = {'Välj datum'}
        open={props.open}
        disablePast = {true}
        onAccept={() => {
          
          props.setIsAccept(true);
          
        }}
        onClose={() => {
          props.setIsAccept(false);
          if (!props.isTextField) {
            props.setOpen(false);
            props.setIsTextField(false);
            
          }
        }}
        
        onOpen={() => {
          if (!props.isAccept) {
            props.setOpen(true);
            
          }
        }}
        startText= {false}
        endText={false}
        value={props.timeValue}
        
        onChange={(newValue) => {
          props.handleDateToggle('');
          props.setValue(newValue);
          
          
        }}
        
        renderInput={(startProps, endProps) => (
          <React.Fragment >
            <TextField  
            
            InputProps={{ style: { fontSize: 11, borderRadius: 100, height: '2.5rem', maxWidth:'100px' }}}
            InputLabelProps={{shrink : false}}
            {...startProps} 
            onFocus={() => {
             
              props.setIsTextField(true);
            }}
            onBlur={() => {
              props.setIsTextField(false);
            }}
            onClick={() => {
              props.setOpen(true);
            }}
            />
            <Box sx={{ mx: 2}}> <Typography variant="subtitle1">till</Typography> </Box>
            <TextField 
            InputProps={{ style: { fontSize: 11, borderRadius:100, height: '2.5rem', maxWidth:'100px' } }}
            InputLabelProps={{shrink : false}}
            {...endProps}
            onFocus={() => {
              props.setIsTextField(true);
            }}
            onBlur={() => {
              props.setIsTextField(false);
            }}
            onClick={() => {
              props.setOpen(true);
            }} 
            
            />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
}
