import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Box } from '@mui/system';
import { NavLink } from "react-router-dom";


export default function Footer () {
    return(
        <Container>
            <Box sx={{mb:3, display: 'flex', flexDirection:'row', borderTop: '1px solid #dee2e6'}}>
            <Box sx={{mt:3, width:{xs:'35%', sm:'20%'} }}>
                    
                    <Typography variant="subtitle1" component="div">
                        Kontakt 
                    </Typography><br/>
                    <NavLink to='/om-oss'>
                        <Typography variant="subtitle1" component="div">
                            Om oss
                        </Typography>
                    </NavLink>
                    <NavLink to='/partners'>
                    <Typography variant="subtitle1" component="div">
                        Teatrar
                    </Typography>
                    </NavLink>
                </Box>
                
            
                <Box sx={{mt:3, width:{xs:'65%', sm:'80%'}, display:'flex', justifyContent: 'flex-end'}}>
                        <NavLink to='/'>
                        <Typography variant="subtitle1" component="div">
                            SHOWY
                        </Typography>
                        </NavLink>
                </Box>
            </Box>
        </Container>
        
            
                
       
    )
}