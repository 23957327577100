import React, {useEffect} from 'react';
import './App.css';
import Typography from '@mui/material/Typography';
import Helmet from 'react-helmet';
import Container from '@mui/material/Container';
import { Box } from '@mui/system';
import Headline from './components/headline/headline';
import { analytics } from './firebase-config';
import { logEvent} from "firebase/analytics";

function Teatrar() {
 
  // Analytics config
  useEffect(() => {
    logEvent(analytics,'partners-page-visited');
  });
 
  const bröd = 
    <div>
      <p>Showy startade med ambitionen att göra det lättare för teatrar att nå ut till en bredare publik. Vi arbetar aktivt för att bredda och förbättra vårt innehåll och för att ge en så rik bild av det aktuella scenutbudet som möjligt. Vi är just nu i uppstarts-fas och arbetar hårt för att skapa en bra upplevelse där så många teatrar som möjligt är representerade. </p>
      <p><b><a href="mailto: info@showy.se">Kontakta oss</a></b> gärna om ni har några frågor, synpunkter eller vill höra mer.</p>
    </div>;

  // Headline contents
  const showURL = 'https://source.unsplash.com/hFTcxZFsG6g';
  const showName = '';
  const showPlace = '';
  const headlineText = 'Anslut till Showy idag';
  return (
  
    <div>
       <Helmet>
          <title>SHOWY | För Teatrar</title>
          <meta 
            name='description'
            content={headlineText}
          />
        </Helmet>
        <Headline headlineText = {headlineText} showURL={showURL} showName = {showName} showPlace = {showPlace} />
        <Container>
          <Box sx={{mt:5, mb:5, minHeight:{sm: 350}}}>      
            <Typography variant="p" sx={{mt: 5, mb: 5}}>
              {bröd}
            </Typography>      
          </Box>
        </Container>
            

        
    </div>
    
       

    


  );
  
}

export default Teatrar;

