import React, {useEffect} from 'react';
import './App.css';
import Typography from '@mui/material/Typography';
import Helmet from 'react-helmet';
import Container from '@mui/material/Container';
import { Box } from '@mui/system';
import Headline from './components/headline/headline';
import { analytics } from './firebase-config';
import { logEvent} from "firebase/analytics";

function About() {
 
   // Analytics config
   useEffect(() => {
    logEvent(analytics,'about-page-visited');
  });
 
  const bröd = 
    <div>
      <p>Vi är generationen som drunknar i det överväldigande utbudet av lättillgänglig on-demand-underhållning. I ljuset av detta är scenkonsten kanske det format som är mest off-demand. Den kräver saker från oss på ett sätt som vi blir programmerade att inte tolerera. Du kan inte spola fram eller spara offline. Du kan inte binge-kolla.</p>
      <p>Men vad den, trots sina irriterande tillkortakommanden erbjuder är löftet om ett intimt band mellan utövare och publik. På scenen finns varken omtag eller klippningar. Det är naket, intimt, flyktigt och omdelelbart. Det är energi som genereras av kroppar och lagras av kroppar. Och det finns inga mediala mellanhänder att skydda sig med. Grekerna som för 2500 år sedan uppfann teatern utvecklade också idén om katarsis, publikens frigörelse från sorg och oro genom den stora tragedins spektakel. Idén om att vi som samhälle kan må bättre av att vara fysiskt närvarande för att ta del av den lättnad och inspiration som berättelsen erbjuder. Showy drivs av denna idé.</p>
      <p>Showy startade med den enkla ambitionen att göra det enklare att ta del av utbudet av scenkonst. Genom att samla aktuella och kommande framträdanden på en plats, gör vi det lättare att upptäcka och ta del av det som produceras och framförs på våra scener. Vi har precis börjat och har en bit kvar men om du vill följa vår resa och framförallt ta del av scenkonst så har du hittat hem.</p>
      <b><a href = "mailto: info@showy.se">Vi skulle bli väldigt glada av att höra från dig!</a></b>
    </div>;

  // Headline contents
  const showURL = 'https://source.unsplash.com/mln2ExJIkfc';
  const showName = '';
  const showPlace = '';
  const headlineText = 'Upplev scenkonst';

  return (
  

   
   
    <div>
       <Helmet>
          <title>SHOWY | Om Oss</title>
          <meta 
            name='description'
            content={headlineText}
          />
        </Helmet>
        <Headline headlineText = {headlineText} showURL={showURL} showName = {showName} showPlace = {showPlace} />
        <Container>
          <Box sx={{mt:5, mb: 5, minHeight:{sm: 350}}}>
            
                  
            <Typography variant="p" sx={{mt: 5, mb: 5}}>
              {bröd}
            </Typography>      
          </Box>
        </Container>
            

        
    </div>
    
       

    
    
    
    


  );
  
}

export default About;

