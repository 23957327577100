import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React from 'react';
import { NavLink } from "react-router-dom";

export default function TopNav () {
    return(
        <Container sx={{pt:2, pb:2}}>
            <Box sx={{display: 'flex', flexDirection:'row'}}>
                    <Box sx={{width:'50%'}}>
                        <NavLink to="/" >
                            <Typography variant="body2" component="div" sx={{letterSpacing:'0.07em'}} >
                            SHOWY
                            </Typography>
                        </NavLink> 
                    </Box>
                    
                    <Box sx={{paddingTop:'5px',width: '50% !important', display: 'flex', flexDirection:'row-reverse'}}>
                        <NavLink to="/om-oss" >
                            <Typography variant="subtitle1" component="div" >
                                Om oss
                            </Typography>
                        </NavLink>
                            
                        <NavLink to="/partners">
                            <Typography variant="subtitle1" component="div" sx={{marginRight:'15px'}}>
                                Teatrar
                            </Typography>
                        </NavLink>
                </Box>     
                
                
            </Box>
                
        </Container>
        
            
               
                
            
            
      
        
    )
}