import * as React from 'react';
import Show4 from '../show4/shows4';




export default function ShowAfterDateBar  (props)  {
    return props.filterShowByDate(props.showMonth, props.showYear).length > 0 ?
    props.filterShowByDate(props.showMonth, props.showYear).map(filteredShow => (
      
      <div key={filteredShow.namn+filteredShow.tid}>
        <Show4
            
            id={filteredShow.id} 
            namn={filteredShow.namn} 
            författare = {filteredShow.författare} 
            bild1 = {filteredShow.bild1} 
            beskrivningKort = {filteredShow.beskrivning.kort}
            beskrivningLång = {filteredShow.beskrivning.lång} 
            tid={filteredShow.tid} 
            plats={filteredShow.teater}
            form={filteredShow.form}
            speltid = {filteredShow.speltid}
            biljett ={filteredShow.biljett}
            
        />
      </div>
    )): null;
  }