import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import './headline.css';

export default function Headline (props) {
    
    return(
        
        <Container maxWidth='xl' id='headline' sx={{position: 'relative', display: {lg:'flex'},justifyContent: {lg:'center'}, height:'fit-content', background: `url(${props.showURL}) ` , backgroundPositionY:'center', backgroundPositionX:'center', backgroundSize:'cover'}}>
            
            <Box sx={{width: {lg: '100%'},height: '100%', paddingLeft:{lg:'24px'}, paddingRight:{lg:'24px'}, maxWidth:'1200px',position: 'inherit', display:'flex', flexDirection: 'column'}}>
                
                <Typography variant="h1" component="div" sx={{mt:10, mb:7, position: 'relative',maxWidth: {xs:'90%', md:'60%'}, color: 'white','wordbreak':'normal'}}>
                    {props.headlineText}
                </Typography>
                {props.showName !== '' ?
                (
                    <Typography  variant="subtitle1" component="div" sx={{display: 'flex', flexDirection:'row-reverse',mb: 3, color: 'white','wordbreak':'normal'}}>
                        {props.showName} | {props.showPlace}
                    </Typography>
                ):null
                }
                
            </Box>
            
               
            
        </Container>
          
    )
}

