import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import CardsModal from '../Modal2/Modal2';
import './shows4.css';
import Container from '@mui/material/Container';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';

export default function Show4(props) {
    
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    var date = new Date(props.tid);
    var dateDay = date.toLocaleDateString("sv-SE", {day: "numeric"});
    var dateWeekday = date.toLocaleDateString("sv-SE", {weekday: "short"});
    var dateMonth = date.toLocaleDateString("sv-SE", {month: "short"});
    const addZero = (i) => {
        if(i<10) {i = '0'+ i}
        return i;
    }
    
    return (
        <Container sx={{ mt:2, mb:2}}>
            <Card sx={{ display: 'flex', borderRadius: '0' }}>
                <CardActionArea sx={{display:'flex', justifyContent:'left'}} onClick={handleOpen}>    
                    
                    
                    <Box sx={{ display: 'flex', height: {sm: 250}, flexDirection: {xs: 'column', sm: 'row'}, width:'100%', minWidth:200 }}>
                        <CardMedia
                            component="img"
                            sx={{ width:{xs: '100%', sm: 220, md:330}, height:{ xs: 300 ,sm: 'auto'}, objectFit: 'cover' }}
                            image={props.bild1}
                            alt="Show image"
                            
                        />
                        <Box id={props.form.toLowerCase()} sx={{width:'100%', display: 'flex', flexDirection:'column' }}>
                            <CardContent sx={{ flex: '1 0 auto', flexDirection:'column' }}>
                                <Typography variant="subtitle1" component="div" sx={{mt:1}} >
                                    {props.form}
                                </Typography>
                                <Typography noWrap component="div" variant="h4" sx={{mt:1, mb:1}}>
                                    {props.namn}
                                </Typography>
            
                                <Typography variant="p" component="div" id="beskrivning-kort" >
                                    {props.beskrivningKort}
                                </Typography>
                            </CardContent>
                            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                            <CardContent>
                                <Box sx={{display:'flex', flexDirection: 'row'}}>
                                <Box sx={{display: 'flex', flexDirection:'row', mr:1}}>
                                    <CalendarTodayOutlinedIcon sx={{mr:1}} fontSize='small'/>
                                    <Typography variant="subtitle1" component="div" >
                                    {dateWeekday} {dateDay} {dateMonth} {date.getHours()}:{addZero(date.getMinutes())}  
                                    </Typography>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection:'row'}}>
                                    <FmdGoodOutlinedIcon fontSize='small' sx={{mr:1}}/> 
                                    <Typography variant="subtitle1" component="div" >
                                        {props.plats} 
                                    </Typography>
                                </Box>
                                </Box>
                            
                            </CardContent>
                            
                        </Box>
                        </Box>
                            
                        
                    </Box>
                    
                    
                </CardActionArea>
                <CardsModal 
                    open={open} 
                    close={handleClose} 
                    bild1={props.bild1} 
                    namn={props.namn} 
                    beskrivningKort={props.beskrivningKort}
                    beskrivningLång={props.beskrivningLång}
                    tid = {props.tid}
                    plats = {props.plats}
                    form = {props.form}
                    författare = {props.författare}
                    speltid = {props.speltid}
                    biljett = {props.biljett}
            />
            </Card>
        </Container>
            
        
    );
}
