import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


export default function DateBar (props) {
    return (props.filterShowByDate(props.showMonth, props.showYear).length > 0) ?
      <Container sx={{position: 'sticky', top:0, backgroundColor:'white', pt:2, pb:2, 'z-index':'5' }}>
          <Typography variant="h3" component="div">{props.showMonth} {props.showYear}</Typography>  
      </Container>
    : null;
  }