import * as React from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import  './navDateToggle.css';




export default function NavDateToggle(props) {
  
  const idag = new Date();
    const imorgon = new Date();
    imorgon.setDate(imorgon.getDate() + 1)
    const vecka = new Date();
    vecka.setDate(vecka.getDate() + 7);
    const månad = new Date();
    månad.setMonth(månad.getMonth() + 1);
   

  return (
     <div >
      
      <ToggleButtonGroup
        
        exclusive
        color='primary'
        value={props.dateToggle}
        onChange={props.handleDateToggle}
        aria-label="date toggle"
        sx = {{mb:1, maxHeight: '2.5rem'}}
        
      >
          <ToggleButton  value="idag" aria-label='Idag' sx={{borderWidth:0, backgroundColor:'rgb(0,0,0,0.1)'  }}>
            Idag
          </ToggleButton>

          <ToggleButton value='vecka' aria-label='Vecka' sx={{borderWidth:0, backgroundColor:'rgb(0,0,0,0.1)' }}>
            Kommande vecka
          </ToggleButton>

          <ToggleButton value='månad' aria-label='Månad' sx={{borderWidth:0, backgroundColor:'rgb(0,0,0,0.1)' }}>
            Kommande månad
          </ToggleButton>
       
       
      </ToggleButtonGroup>
    </div> 
  );
}