import React, { useEffect, useState } from 'react';
import db from './firebase-config';
import  {analytics}  from './firebase-config';
import { logEvent} from "firebase/analytics";
import { collection,  where, query, limit, startAfter, getDocs } from "firebase/firestore";
import './App.css';
import Nav from './components/Nav/nav';
import DateBar from './components/dateBar/dateBar';
import ShowAfterDateBar from './components/showAfterDateBar/showAfterDateBar';
import Headline from './components/headline/headline';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import InfiniteScroll from 'react-infinite-scroller';
import Helmet from 'react-helmet';
import Container from '@mui/material/Container';
import UpButton from './components/upButton/upbutton';



function App() {
  
    // Analytics config
    useEffect(() => {
      logEvent(analytics,'homepage-visited');
    });

  const [shows, setShows] = useState([{namn: 'loading...', id: 'loading'}]);
  const [lastVisible, setLastVisible] = useState();  
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [scroll, setScroll] = useState(false);


  /* navPlats*/ 
  const platsInData = ['Dramaten', 'Kulturhuset Husby','Kulturhuset Sergels torg','Kulturhuset Vällingby','Kungliga Operan'];
  const [scen, setScen] = React.useState(platsInData.map(plats => plats));
  const isAllSelected = platsInData.length > 0 && scen.length === platsInData.length;
  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "Alla") {
      setScen(scen.length === platsInData.length ? [] : platsInData);
      return;
    }
    setScen(value);
  };

  /* navFormList*/ 
  const formsInData = ['Teater', 'Balett', 'Opera', 'Konsert'];
  const [formats, setFormat] = React.useState(formsInData.map(form => form));
  const allSelected = formsInData.length > 0 && formats.length === formsInData.length;
  const handleFormat = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "Alla") {
      setFormat(formats.length === formsInData.length ? [] : formsInData);
      return;
    }
    setFormat(value);
  };

  
  /* navDateTogge */
  
  const [dateToggle, setDateToggle] = React.useState('vecka');
  const handleDateToggle = (event, newDate) => {setDateToggle(newDate);};
  


  /* navDateRangePicker */
  const today = new Date();
  const oneMonth = new Date();
  oneMonth.setMonth(oneMonth.getMonth() + 1);
  const [timeValue, setValue] = React.useState([today, oneMonth]);
  const [open, setOpen] = React.useState(false);
  const [isAccept, setIsAccept] = React.useState(false);
  const [isTextField, setIsTextField] = React.useState(false);
  
  let fromDate =[];
  let toDate = [];

  if(timeValue[0] !== null && timeValue[1] !== null){
     fromDate = new Date(timeValue[0].getTime() - (timeValue[0].getTimezoneOffset() * 60000)).toISOString().split('T')[0];
     toDate = new Date(timeValue[1].getTime() - (timeValue[1].getTimezoneOffset() * 60000)).toISOString().split('T')[0];
  
  }
  
  useEffect(() =>{

    const idag = new Date();
    const imorgon = new Date();
    imorgon.setDate(imorgon.getDate() + 1)
    const vecka = new Date();
    vecka.setDate(vecka.getDate() + 7);
    const månad = new Date();
    månad.setMonth(månad.getMonth() + 1);

    
    if (dateToggle === 'idag'){
      setValue([idag,imorgon])
    }else if(dateToggle === 'vecka' ){
      setValue([idag, vecka])
    }else if(dateToggle === 'månad'){
      setValue([idag, månad])
    }else  {};
  }, [dateToggle])

  
  useEffect(() => {
    const getData = async () => {
      
      const q = query(collection(db, "shows"), 
        where("tid", ">", fromDate), 
        where("tid", "<", toDate), 
        /*where("form", 'in', formats.length > 0 ? formats.map(format => format):['none']),*/ 
        where('teater', 'in', scen.length > 0 ? scen.map(scenen => scenen): ['none']),
        limit(10));
      const noData = q.size === 0;
      
      if(!noData){
      const showData = await getDocs(q);
      const newShows = showData.docs.map((doc) => ({...doc.data(), id: doc.id }));
      setLastVisible(showData.docs[showData.docs.length-1]);
      setShows(newShows);
      setLoading(false);
      setIsEmpty(false);
      }else{
        setIsEmpty(true);
      }  
    };
      getData(); 
  }, [fromDate, toDate, scen]);

let getNextData = () => {

}

if(!scroll){
  
  getNextData = async () => {
    setScroll(true);
    const q = query(collection(db, "shows"), 
      where("tid", "<", toDate),
      where('teater', 'in', scen.length > 0 ? scen.map(scenen => scenen): ['none']), 
      startAfter(lastVisible), 
      limit(20));
      
      const showData = await getDocs(q);
      const noData = showData.size === 0;
      if(!noData){
      setLastVisible(showData.docs[showData.docs.length-1]);
      const newShows = showData.docs.map((doc) => ({...doc.data(), id: doc.id }));                      
      setShows((shows) => [...shows, ...newShows]);
      setScroll(false);
      }else{
      setIsEmpty(true);
      setScroll(false);
     
    }
    
  }
  
}else{};



  
const transformShows = 
  shows.map(({id, teater, namn, författare, bild1, beskrivning, stad, tid, speltid, form, biljett}) => 
  ({id, teater, namn, författare, bild1, beskrivning, stad, tid, speltid, form, biljett }))
  .filter((a) => (formats.map(format => format).includes(a.form)) )  ;


/*const transformShows = shows.map(show => show).filter((a) => scen.map(scen => scen).includes(a.teater));*/

  const filterShowByDate = (searchedMonth, searchedYear) => {
    return transformShows.filter(show => (new Date(show.tid).toLocaleDateString("sv-SE", {month: "long"}) === searchedMonth && new Date(show.tid).getFullYear() === searchedYear))
  } 

  const datesToFilterBy = [
    {år: 2022, månad:'januari'},
    {år: 2022, månad:'februari'},
    {år: 2022, månad:'mars'},
    {år: 2022, månad:'april'},
    {år: 2022, månad:'maj'},
    {år: 2022, månad:'juni'},
    {år: 2022, månad:'juli'},
    {år: 2022, månad:'augusti'},
    {år: 2022, månad:'september'},
    {år: 2022, månad:'oktober'},
    {år: 2022, månad:'november'},
    {år: 2022, månad:'december'}, 
    {år: 2023, månad:'januari'} 
  ]

  // headline content
  const showURL = 'https://cms.dramaten.se/Media/tw1atpev/arv_cover.jpg?width=1920&height=1080&mode=crop&center=0.53037037037037038,0.27';
  const showName = 'Arv';
  const showPlace = 'Dramaten';
  const headlineText = 'Upptäck teater, opera och dans-föreställningar i Stockholm. ';
  const headlineHeight= '450px';

  const up = () =>{
    const filter = document.querySelector('#filter');
    filter.scrollIntoView({behavior:'smooth', block:'start'});
};


  return (

    <div>
    <Helmet>
      <title>SHOWY | Startsida</title>
      <meta 
        name='description'
        content='Upptäck aktuella och kommande teater, opera och dans-föreställningar i Stockholm.'
      />
    </Helmet>
    <Headline headlineHeight={headlineHeight} headlineText = {headlineText} showURL={showURL} showName = {showName} showPlace = {showPlace} />
    <Nav allSelected={allSelected} dateToggle={dateToggle} setDateToggle={setDateToggle} handleDateToggle={handleDateToggle} isAllSelected={isAllSelected} platsInData = {platsInData} formsInData={formsInData} transformShows={transformShows}  scen={scen} handleChange={handleChange} formats={formats} handleFormat={handleFormat} timeValue={timeValue} setValue={setValue} open={open} setOpen={setOpen} isAccept={isAccept} setIsAccept ={setIsAccept} isTextField={isTextField} setIsTextField ={setIsTextField} />
    <UpButton/>
    <div id="show-list" >
      
    {
      loading === true ? (
        <Container>
          
            <Box justifyContent='center' sx={{ display: 'flex', mt:5, mb:5 }} >
              <CircularProgress color="inherit"  />
            </Box>
          
        </Container>
      )

      :transformShows.length === 0 && shows.length < 5 ? (
        <Container>
          <Box sx={{mt:5, mb:5}}>
            <Typography variant="p" component="div" align='center'>
                Inget att visa just nu, testa att filtrera annorlunda.
            </Typography>
          </Box>
        </Container>)
        :
        
        datesToFilterBy.map(date => (
        <div key={date.månad+date.år}>
        <DateBar  filterShowByDate={filterShowByDate} showMonth={date.månad} showYear={date.år} />
        <ShowAfterDateBar filterShowByDate={filterShowByDate} showMonth={date.månad} showYear = {date.år} />
        </div> 
      ))
      
    }
    {
    
    transformShows.length === 0 && (shows.length === 1 || shows.length === 0) ?
    <div></div>:
    (!isEmpty && !loading && formats.length !== 0) ?
    
    (
      <Container>
        <Box justifyContent='center' sx={{ display: 'flex', mb:5 }} >
        
        <InfiniteScroll
          pageStart={0}
          loadMore={getNextData}
          hasMore={true || false} 
          threshold={150}
          loader={
            <div className="loader" key={0}>
              <Box justifyContent='center' sx={{ display: 'flex', mt:5 }}>
                <CircularProgress color="inherit" />
              </Box></div>}
        >
           {<div></div>}
        </InfiniteScroll>
        </Box>
    </Container>
    ):
    
    (
      <Container>
        <Box sx={{mt:5, mb:5}}>
          <Typography variant="p" component="div" align='center' sx={{mt: 5}}>
              Inget mer att visa just nu, <span onClick={up} style={{textDecoration:'underline', cursor:'pointer'}}>testa att filtrera annorlunda.</span>
          </Typography>
        </Box>
    </Container>
    )
    }
    </div>

   
    
    

</div>
  );
  
}

export default App;

