import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';




export default function NavFormList(props) {
  
  return (
    
        <div >
           
            <FormControl sx={{borderStyle: {'&::before':0} , minWidth: 80}}>
                
                <Select
                    
                    
                    multiple
                    value={props.formats}
                    onChange={props.handleFormat}
                    displayEmpty
                    renderValue={props.formats.length === props.formsInData.length ? (selected) => 'Alla': (props.formats.length !== props.formsInData.length && props.formats.length > 0) ? (selected) => props.formats.join(", "): props.formats.length === 0 ? (selected) => 'Välj' : null}
                    inputProps={{ 'aria-label': 'Without label' }}
                    color="primary"
                    sx={{ height:'2.5rem', borderRadius: '100px', maxWidth:150,fontSize:'11px'}}
                    
                >
                
                <MenuItem value="Alla"  sx={{borderBottom:'1px solid #d0d2d5',fontSize: 11, backgroundColor: props.allSelected ? 'rgba(0, 0, 0, 0.08)' : 'transparent' }}>
                    <Checkbox
                        checked={props.allSelected} 
                        indeterminate={props.formats.length > 0 && props.formats.length < props.formsInData.length}
                    />
                    Alla  
                </MenuItem>
                
                {props.formsInData.map((form) => (
                    <MenuItem key={form+'id'} value={form} sx={{fontSize: 11}}>
                        <Checkbox checked={(props.formats.indexOf(form) > -1)} />
                        {form}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
           
        </div>
          
  );
}