import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from './App';
import About from './About';
import TopNav from './components/topNav/topNav';
import Footer from './components/footer/footer';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Teatrar from './FörTeatrar';
import Helmet from 'react-helmet';


const theme = createTheme({
  palette: {
    primary: {
      light: '#ffffff',
      main: '#000000',
      dark: '#000000',
      contrastText: '#fff',
    }
  },
  
  typography: {
    allVariants: {
      fontFamily: 'Red Hat Text, sans-serif',
      textTransform: 'none',
      
    },
    a:{
      color: 'black !important',
    },
    h1:{
      fontSize: 50,
      fontWeight: '500',
    },

    h2:{
      fontSize: 30,
      textTransform: 'uppercase',
      fontWeight: '500'
    },

    h3:{
      fontSize: 19,
      textTransform:'uppercase',
      fontWeight: '500',
      lineHeight: 1.3 
    },
    h4:{
      fontSize: 17,
      textTransform:'uppercase',
      fontWeight: '500',
      lineHeight: 1.3 
    },
    h5:{
      fontSize: 17,
      textTransform:'uppercase',
      fontWeight: '500',
      lineHeight: 1.3 
    },
    subtitle1:{
      fontSize:12,
      textTransform: 'uppercase',
      fontWeight: '400',
    },
    subtitle2:{
      fontSize:12,
      fontWeight: '400',
      
    },
    body1:{
      fontSize: 14,
      lineHeight:1.3, 
      fontWeight: '400'
    },
    p:{
      fontSize: 14,
      lineHeight:1.3, 
      fontWeight: '300'
    },
    body2:{
      fontSize: 19,
      textTransform:'uppercase',
      fontWeight: '600'
    },
    button:{
      fontSize: 11,
      fontWeight: '400'

    }    
  }

});



ReactDOM.render(
  <Router>
    <Helmet>
      <title>SHOWY</title>
      <meta 
        name="description"
        content="Upptäck aktuella och kommande teater, opera och dansföreställningar i Stockholm"
      />
      <meta 
        name="keywords"
        content="Teater, Opera, Balett, Dans, Stockholm"
      />
    </Helmet>
    <ThemeProvider theme={theme}>
      <TopNav/>
      <Routes>
        <Route path='/' element={<App/>}/>
        <Route path="/om-oss"  element={<About />} />
        <Route path="/partners"  element={<Teatrar />} />
      </Routes>
      <Footer/>
    </ThemeProvider>
    
  </Router>,
   
    
  
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
