import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './navPlats.css';
import Checkbox from '@mui/material/Checkbox';




export default function NavPlats(props) {
  
  return (
    
        <div >
           
            <FormControl sx={{ minWidth: 80}}>
                
                <Select
                    
                    
                    multiple
                    value={props.scen}
                    onChange={props.handleChange}
                    displayEmpty
                    renderValue={props.scen.length === props.platsInData.length ? (selected) => 'Alla': (props.scen.length !== props.platsInData.length && props.scen.length > 0) ? (selected) => props.scen.join(", "): props.scen.length === 0 ? (selected) => 'Välj' : null}
                    inputProps={{ 'aria-label': 'Without label' }}
                    color="primary"
                    sx={{ height:'2.5rem', borderRadius: '100px', maxWidth:150, fontSize:'11px'}}
                >
                
                <MenuItem value="Alla"  sx={{borderBottom:'1px solid #d0d2d5',fontSize: 11, backgroundColor: props.isAllSelected ? 'rgba(0, 0, 0, 0.08)' : 'transparent' }}>
                    <Checkbox
                        checked={props.isAllSelected} 
                        indeterminate={props.scen.length > 0 && props.scen.length < props.platsInData.length}
                    />
                    Alla  
                </MenuItem>
                
                {props.platsInData.map((plats) => (
                    <MenuItem key={plats+'id'} value={plats} sx={{fontSize: 11}}>
                        <Checkbox checked={(props.scen.indexOf(plats) > -1)} />
                        {plats}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
           
        </div>
          
  );
}