import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import './upButton.css';
import FilterListIcon from '@mui/icons-material/FilterList';

export default function UpButton () {
    
    const up = () =>{
        const filter = document.querySelector('#filter');
        filter.scrollIntoView({behavior:'smooth', block:'start'});
    };

  
    const [isVisible, setIsVisible] = useState(false);
    
    const getOffset = (element) => {
        const rect = element?.getBoundingClientRect(),
          scrollTop = 
            window.pageYOffset ||  document.documentElement.scrollTop;
        return rect.top + scrollTop + 300;
      };
    
    const listenToScroll = () => {
  
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        const heightToHideFrom = getOffset(document.querySelector("#show-list"));
            if (winScroll > heightToHideFrom) { 
               !isVisible &&      // to limit setting state only the first time         
                 setIsVisible(true);
            } else {
                 setIsVisible(false);
            }  
          };


    
    useEffect(() => {   

        window.addEventListener("scroll", listenToScroll);
        return () => 
           window.removeEventListener("scroll", listenToScroll); 
      }, [])

      
   
        return  (
            isVisible &&
            (
            <Box id='up-button' sx={{paddingRight:{xs:'16px', sm:'24px'}}}> 
                <Button variant='outlined' sx={{borderRadius:'100px', position: 'relative'}}  onClick={up}><FilterListIcon sx={{pr:1}}/>Filtrera</Button>  
            </Box>
            )
        )
    
    
  }